import networkUrl from "../config/network.json"

export const serverAddress = process.env.NODE_ENV === "development" ? `https://xtaxi-dashboard-test.autox.tech/api` : `${window.location.origin}/api`;
export const oauthAddress = networkUrl[process.env.REACT_APP_EN_NAME].oauth;
export const kRiderClassValues = {
    regular: 0,
    vip: 2,
    dummy: 4,
    demo: 5,
    blocked: -1,
    trgs: 6,
    externalG: 7,
    externalU: 8,
    externalG_Pingshan: 9
};
export const kRiderClassNames = {
    [kRiderClassValues.vip]: "Test",
    [kRiderClassValues.regular]: "AutoX",
    [kRiderClassValues.dummy]: "Dummy",
    [kRiderClassValues.demo]: "xGuest",
    [kRiderClassValues.blocked]: "Blocked",
    [kRiderClassValues.trgs]: "Trgs",
    [kRiderClassValues.externalG]: "ExternalG_Beijing",
    [kRiderClassValues.externalG_Pingshan]: "ExternalG_Pingshan",
    [kRiderClassValues.externalU]: "ExternalU",
};

export const kVehiclesClassValues = {
    both: "/",
    demoReady: "DemoReady",
    driverless: "Driverless",
    stress: "Stress"
};

export const kVehiclesClassNames = {
    [kVehiclesClassValues.both]: "Both",
    [kVehiclesClassValues.demoReady]: "Drivered",
    [kVehiclesClassValues.driverless]: "Driverless",
    [kVehiclesClassValues.stress]: "Stress"
};
